<template>
  <div style="width: 100%; height: 100%">
    <div id="qrCode"></div>
  </div>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import QRCode from 'qrcode-js-package/qrcode.js'

export default {
  name: 'a-qrcode',
  mixins: [mixin],
  props: {
    CSS: {
      type: String,
      description: 'CSS стили'
    },
    textType: {
      type: String,
      default: 'constant',
      description: 'Тип источника',
      editor: 'Select',
      options: {
        options: [
          { id: 'constant', name: 'Константа' },
          { id: 'variable', name: 'Переменная' }
        ]
      }
    },
    text: { type: String, required: false, description: 'Значение', default: 'Текст' }
  },
  watch: {
    variableValue: function () {
      this.clear()
      this.makeCode()
    }
  },
  data () {
    return {
      qrCode: {}
    }
  },

  mounted () {
    this.observer = new ResizeObserver(this.resizeHandler)
    this.observer.observe(this.$el)
    this.qrCode = new QRCode(this.$el.querySelector('#qrCode'), {
      text: this.value,
      width: this.$el.clientWidth,
      height: this.$el.clientHeight,
      colorDark: '#000',
      colorLight: '#FFF',
      correctLevel: QRCode.CorrectLevel['H']
    })
    this.makeCode()
  },

  beforeDestroy () {
    this.observer && this.observer.unobserve(this.$el)
  },

  methods: {
    resizeHandler () {
      let size = 100
      if (this.$el.clientHeight >= this.$el.clientWidth) {
        size = this.$el.clientWidth
      } else {
        size = this.$el.clientHeight
      }
      this.qrCode._htOption.height = size
      this.qrCode._htOption.width = size
      this.qrCode._oDrawing._htOption.height = size
      this.qrCode._oDrawing._htOption.width = size
      this.qrCode._oDrawing._elCanvas.height = size
      this.qrCode._oDrawing._elCanvas.width = size
      this.size = size
      this.makeCode()
    },
    makeCode: function () {
      this.qrCode.clear()
      this.qrCode.makeCode((this.variableValue || '') + '')
    }
  },

  computed: {
    variableValue () {
      if (this.textType === 'variable' && this.text) {
        let value = null
        let isGetModel = this.getModel() && typeof this.getModel()[this.text] !== 'undefined'
        let isGetRawData = this.getRawData() && typeof this.getRawData()[this.text] !== 'undefined'

        // проверить является ли переменная (this.text) ссылкой
        let isXref = null
        if (isGetRawData) {
          try {
            isXref = Array.isArray(JSON.parse(this.getRawData()[this.text]))
          } catch (e) {
            isXref = Array.isArray(this.getRawData()[this.text])
          }
        }
        if (isGetModel && !isXref) {
          value = this.getModel()[this.text]
        } else if (isGetRawData) {
          value = this.getRawData()[this.text]
        }
        try {
          value = JSON.parse(value)
        } catch (e) {
        }

        if (value && Array.isArray(value)) {
          value = value.map(item => item.name).join(',')
        }

        if (value == null || value === undefined || value === '') {
          return this.text
        } else {
          return value
        }
      }
      return this.text
    }
  }
}
</script>

<style scoped>

</style>
